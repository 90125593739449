<template>
  <div>
    <v-container v-if="appState.mustCharge">
      <v-row content="center" align-content="center">
        <v-col align="center">
          <v-icon x-large color="red">
            mdi-battery-off
          </v-icon>
        </v-col>
      </v-row>

      <v-row>
        <v-col align="center">
          <h1>{{ $t('Battery is too low') }}</h1>
          <p>{{ $t('This app works mostly offline, but your device should keep the minimum battery level') }}</p>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else-if="appState.mustConnect && !appState.isConnected">
      <v-row content="center" align-content="center">
        <v-col align="center">
          <v-icon x-large color="red">
            mdi-signal-off
          </v-icon>
        </v-col>
      </v-row>

      <v-row>
        <v-col align="center">
          <h1>{{ $t('No internet connection') }}</h1>
          <p>{{ $t('Internet connection is required for this app to initialize') }}</p>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Auth',

  data () {
    return {
      preflight: null
    }
  },

  computed: {
    checked () {
      return !!this.preflight
    },

    noInternet () {
      return !this.appState.isConnected
    },

    batteryLow () {
      if (this.$nosework.context.device().platform === 'web' || this.$nosework.context.device().platform === 'electron') {
        return false
      }

      return this.$isCharging !== true && this.$batteryLevel <= 0.3
    }
  },

  mounted () {
    this.preflight = this.$nosework.context.preflight()

    this.$store.commit('setNavigation', {
      show: false
    })
  }
}
</script>
